var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{attrs:{"action":"#"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[(!_vm.inline)?[_c('div',{staticClass:"common-modal-headline"},[_c('h4',{staticClass:"common-modal-title"},[_vm._v("Contact us")]),_c('span',{staticClass:"material-symbols-rounded common-modal-close",on:{"click":function($event){return _vm.$emit('onClose')}}},[_vm._v(" close ")])]),_c('hr',{staticClass:"my-3"})]:_vm._e(),_c('b-alert',{staticClass:"mt-3",attrs:{"variant":_vm.msg.type,"dismissible":"","show":_vm.msg.text},model:{value:(_vm.msg.has),callback:function ($$v) {_vm.$set(_vm.msg, "has", $$v)},expression:"msg.has"}},[_vm._v(_vm._s(_vm.msg.text))]),_c('div',{staticClass:"form-group mb-3"},[_c('label',{attrs:{"for":"contact-email"}},[_vm._v("Email")]),_c('div',{staticClass:"input-group input-group-merge"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],staticClass:"form-control",class:{
          'is-invalid': _vm.submitted && _vm.$v.form.email.$error,
        },attrs:{"id":"contact-email","placeholder":"Enter your email"},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "email", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.email.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.email.required)?_c('div',[_vm._v("Email is required")]):_vm._e(),(!_vm.$v.form.email.email)?_c('div',[_vm._v("Email is invalid")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group mb-3"},[_c('label',{attrs:{"for":"contact-message"}},[_vm._v("Message")]),_c('div',{staticClass:"input-group input-group-merge"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.message),expression:"form.message"}],staticClass:"form-control form-control-textarea",class:{
          'is-invalid': _vm.submitted && _vm.$v.form.message.$error,
        },attrs:{"maxlength":1024,"rows":"3","placeholder":"Enter message. Message has a limit of 1024 chars."},domProps:{"value":(_vm.form.message)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "message", $event.target.value)}}}),_c('div',{staticClass:"form-control-textarea-counter"},[(_vm.form.message)?_c('div',{staticClass:"badge",class:{
            'badge-success': _vm.form.message.length !== 1024,
            'badge-danger': _vm.form.message.length === 1024,
          }},[_vm._v(" "+_vm._s(_vm.form.message.length)+" / 1024 ")]):_vm._e()]),(_vm.submitted && _vm.$v.form.message.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.message.required)?_c('div',[_vm._v("Message is required")]):_vm._e(),(!_vm.$v.form.message.minLength)?_c('div',[_vm._v("Min is 3")]):_vm._e(),(!_vm.$v.form.message.maxLength)?_c('div',[_vm._v("Max is 1024")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group mb-3",class:_vm.inline ? 'text-center text-lg-right' : 'text-center text-md-left'},[_c('vue-recaptcha',{ref:"recaptcha",staticClass:"d-inline-block",class:{
        'is-invalid': _vm.submitted && _vm.$v.form.token.$error,
      },attrs:{"sitekey":_vm.sitekey},on:{"verify":_vm.onVerify,"expired":_vm.onExpired}}),(_vm.submitted && _vm.$v.form.token.$error)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Check captcha please ")]):_vm._e()],1),(!_vm.inline)?_c('hr',{staticClass:"mt-2 mb-1"}):_vm._e(),_c('div',{staticClass:"mt-3",class:_vm.inline ? 'text-center text-lg-right' : 'text-center text-md-left'},[_c('button',{staticClass:"btn btn-primary btn-mw-220",attrs:{"type":"submit","disabled":_vm.trySubmit}},[_vm._v(" Send message ")])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }